var isRTL = ($('html').attr('dir') == "rtl") ? true : false,
    winWidth = $(window).width(),
    winHeight = $(window).height();
    winHeight = $(window).height();

$(function() {
    browserDetect();
});

$(window).on('load', function() {
    // Do after Page ready
    doOnLoad();
    // pageLoader();
    
});

$(window).on('resize orientationchange', function() {
    // Do on resize
    winWidth = $(window).width(),
    winHeight = $(window).height();
});

$(window).on('scroll', function() {
    //Do on Scroll
});

$(document).keyup(function(e) {
    if (e.keyCode == 27) {
        //Do on ESC press
    }
});

function doOnLoad() {
    //OnLoad Function
    setTimeout(function() {
        addVideoPlugin();
    }, 4000);
    forGsap();
    secMinHeight2();
    // lottieAnimation();
    secMinHeight();
    
}

function browserDetect() {
    navigator.sayswho = (function() {
        var ua = navigator.userAgent,
            tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join('').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    })();
    $('body').addClass(navigator.sayswho);
}

function addVideoPlugin() {
    if (winWidth > 1024 && $('.js-video').get(0)) {
        var plyrScriptElement = document.createElement("script");
        if (isRTL) {
            plyrScriptElement.setAttribute('src', customVariables.baseURL + '../assets/js/plyr.min.js');
        } else {
            plyrScriptElement.setAttribute('src', customVariables.baseURL + 'assets/js/plyr.min.js');
        }
        plyrScriptElement.setAttribute('async', 'true');
        document.body.appendChild(plyrScriptElement);
    } else {
        jsVideoDirect();
    }
}

var players = [];

function jsVideo() {
    // Custom player
    if ($('.js-video').length) {
        $('.js-video').each(function(i) {
            var thisParent = $(this).parent();
            players[i] = new Plyr(this, {
                playsinline: true,
            });
            thisParent.find('.plyr').attr('data-video-instance', i);
        });
    }
}

function jsVideoDirect() {
    if ($('.js-video').length) {
        $('.js-video').each(function(i) {
            $(this).attr('data-video-instance', i);
            var videoId = $(this).attr('data-plyr-embed-id');
            $(this).html('<iframe width="100%" height="100%" src="https://www.youtube.com/embed/' + videoId + '?rel=0&playsinline=1&enablejsapi=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>');
        });
    }
}

function secMinHeight() {
    $('.js-minHeight').each( function(i,e) {
        var $this = $(e);
        // minHeight = $(window).innerHeight();
        // console.log('a', winHeight)
        $this.css('min-height', `100vh`);
        //$this.hasClass('minHeight--modify') ? $this.css('min-height', `${minHeight * 0.75}px`) : false;
    })

}
function secMinHeight2() {
    $('.js-minHeight2').each( function(i,e) {
        var $this = $(e);
        minHeight2 = $(window).innerHeight() + 200;
        // console.log('a', winHeight)
        $this.css('min-height', `${minHeight2}`);
        //$this.hasClass('minHeight--modify') ? $this.css('min-height', `${minHeight * 0.75}px`) : false;
    })
}
// function lottieAnimation(){
//     var animData = {
//         container: document.getElementById('bodymovin'),
//         path: 'assets/jsonFiles/data.json', 
//         render: 'svg',
//         loop: false,
//         autoplay: false,
//         name: "animScroll",
//     },
//     animScroll,
//     tl,
//     timeObj = {currentFrame: 1};

//     animScroll = bodymovin.loadAnimation(animData)
//     tl = gsap.timeline({    
//         scrollTrigger: {
//             trigger: '#bodymovin',
//             start: "top top",
//             // end: () => "+=" + document.querySelector('.fr-stick').offsetHeight,
//             end: "+=10000",
//             scrub: 2,
//             pin: true,
//             pinSpacing: true,
//         },
//     })
//     animScroll.addEventListener('DOMLoaded', function () {
//         tl.to(timeObj, 1, {
//             currentFrame: animScroll.totalFrames-1,
//             onUpdate: function() {
//                 animScroll.goToAndStop(Math.round(timeObj.currentFrame), true)
//             },
//         });
//     })
// }

function forGsap(){
    const actualHeight = window.innerHeight;
    const elementHeight = document.getElementById('control-height').clientHeight;
    
    const barHeight = elementHeight - actualHeight;
    console.log(barHeight)
    var animData = {
        container: document.getElementById('bodymovin'),
        path: 'assets/jsonFiles/data.json', 
        render: 'svg',
        loop: false,
        autoplay: false,
        name: "animScroll",
    },
    animScroll,
    tl,
    timeObj = {currentFrame: 1};
    animScroll = bodymovin.loadAnimation(animData)


    // Images Loading
    const canvas = document.getElementById("hero-lightpass");
    const context = canvas.getContext("2d");
    var totalImagesDownloaded = 0;
    var totalImagesCount = 254;

    canvas.width = $('body').innerWidth() ;
    canvas.height = $(window).innerHeight() + barHeight;


    const frameCount = totalImagesCount;

    const airpods = {
        frame: 0
    };
    const currentFrame = index => (
        `assets/img/webp/Trilux_Ani_V3_${(index + 1).toString().padStart(5, '0')}.webp`
    );
   
    const images = [];
    var loadertl= gsap.timeline({});
    
    for (let i = 0; i < totalImagesCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        img.onload = function() {
            totalImagesDownloaded += 1;
            updateLoader();
        }
        images.push(img);
    }
    images[0].onload = render;

    function render() {
        context.clearRect(0, 0, canvas.width, canvas.height);
        //   context.drawImage(images[airpods.frame], 0, 0, winWidth, winHeight, 0, 0, winWidth, winHeight); 
        drawImageProp(context, images[airpods.frame], 0, 0, $('body').innerWidth(), $(window).innerHeight() + barHeight)
    }
    function updateLoader(){
        var perc = 100 * totalImagesDownloaded / totalImagesCount;
        document.getElementById("tal").innerHTML = Math.round(perc) + '%';
        // $(".count-text").stop().animate({
        //     left: perc + '%',
        // }, 200)
        $("#tal").css({
            transform: `translate(${perc}%, 0)`
        })
        if(perc > 99 ){
            $(".pageloader" ).css({
                display:'none'
                
            });
            $('html').removeClass('overFlow-none')
            tlforWelcomeScreen ();
            
        }
    }
        function tlforWelcomeScreen (){
            var tlForWelcomeScreen = gsap.timeline({})
            tlForWelcomeScreen.fromTo('.js-mainText1' ,1.5, {opacity:0,x:50}, {opacity:1,x:0})
            tlForWelcomeScreen.fromTo('.js-mainText2' ,1.5, {opacity:0,x:-50}, {opacity:1,x:0},'-=1.5')
            tlForWelcomeScreen.fromTo('.js-mainText3' ,1.5, {opacity:0,x:50}, {opacity:1,x:0},'-=1.5')
            tlForWelcomeScreen.fromTo('.logo-sec',1.5,{opacity:0}, {opacity:1},'-=1.5')
            tlForWelcomeScreen.fromTo('.menu-sec-span',0.5,{x:38}, {x:0},'-=1.5' )
            tlForWelcomeScreen.fromTo('.js-border',0.5,{x:-49}, {x:0},'-=1.5' )
            tlForWelcomeScreen.fromTo('.js-tryoutText',0.5,{opacity:1,y:30,display:'block'}, {opacity:1,y:0},'-=1.2' )
            // tlForWelcomeScreen.fromTo('.scrollText-div',1.5,{opacity:0,y:20,}, {opacity:1,y:0},'-=1.5' )
            tlForWelcomeScreen.fromTo('.svgAnimation-sec',1.5,{opacity:0,y:53,}, {opacity:1,y:0},'-=1.5' )
            tlForWelcomeScreen.fromTo('.circle-svg',0.5,{opacity:0,y:50,}, {opacity:1,y:0},'-=1.0')

        }
        ///  Script For Canvas Image object-fit Cover
        function drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {

            if (arguments.length === 2) {
                x = y = 0;
                w = ctx.canvas.width;
                h = ctx.canvas.height;
            }
    
            // default offset is center
            offsetX = typeof offsetX === "number" ? offsetX : 0.5;
            offsetY = typeof offsetY === "number" ? offsetY : 0.5;
    
            // keep bounds [0.0, 1.0]
            if (offsetX < 0) offsetX = 0;
            if (offsetY < 0) offsetY = 0;
            if (offsetX > 1) offsetX = 1;
            if (offsetY > 1) offsetY = 1;
    
            var iw = img.width,
                ih = img.height,
                r = Math.min(w / iw, h / ih),
                nw = iw * r,   // new prop. width
                nh = ih * r,   // new prop. height
                cx, cy, cw, ch, ar = 1;
    
            // decide which gap to fill    
            if (nw < w) ar = w / nw;                             
            if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
            nw *= ar;
            nh *= ar;
    
            // calc source rectangle
            cw = iw / (nw / w);
            ch = ih / (nh / h);
    
            cx = (iw - cw) * offsetX;
            cy = (ih - ch) * offsetY;
    
            // make sure source rectangle is valid
            if (cx < 0) cx = 0;
            if (cy < 0) cy = 0;
            if (cw > iw) cw = iw;
            if (ch > ih) ch = ih;
    
            // fill image in dest. rectangle
            ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
        }

    // timeline for Page loader


    // timline for Flexible Workspace Text Animation 
    gsap.registerPlugin(ScrollTrigger);   
    
    // tl.to('.svgAnimation-sec' ,{opacity:1, duration:1},)
    minHeight = $(window).innerHeight();
    var scaleValue = 1;
    animScroll.addEventListener('DOMLoaded', function () { 

        var tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".fr-stick",
                start: "top top",
                // end: () => "+=" + document.querySelector('.fr-stick').offsetHeight,
                end: "+=8000",
                scrub: 2,
                pin: true,
                pinSpacing: true,
            },
            
        })
        
        tl.to('.header-sec' , {opacity:0 , y:-50, duration:1})
        tl.to('.mainText-sec' , {opacity:0 , y:-100, duration:1},'-=0.6')
        
        tl.to('.scrollText-div', {y:50, opacity: 0,duration:1},'-=0.6')
        tl.fromTo('.svgAnimation-sec', 2 ,{yPercent:53,zIndex:9},{yPercent:0,},'-=1.8')
        tl.fromTo('#bodymovin svg', 2 ,{scale:2},{scale:1},'-=2')
        tl.fromTo('path[stroke-width="6"]', 2 ,{strokeWidth: 3},{strokeWidth: 6},'-=2')
        tl.to('.mainBody-div' , {yPercent:-10,duration:1,height: '100%',})

        tl.to(timeObj, 60, {currentFrame: animScroll.totalFrames-1,onUpdate: function() {animScroll.goToAndStop(Math.round(timeObj.currentFrame), true)},});
        tl.fromTo('.js-svg-text1',1.5,{opacity: 0 , y : 50 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=60')
        tl.fromTo('.js-svg-text1',1.5,{ y : 0, opacity: 1,display: "block",top:0},{ y : -50, opacity: 0, top:0,display: "none" },'-=55')
        tl.fromTo('.js-svg-text2',1.5,{display: "none", opacity: 0 , y : 50 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=54')

        tl.fromTo('.js-svg-text2',1.5,{ y : 0, opacity: 1,display: "block",top:0},{ y : -50, opacity: 0, top:0,display: "none" },'-=45')
        tl.fromTo('.js-svg-text3',1.5,{display: "none", opacity: 0 , y : 50 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=44')

        tl.fromTo('.js-svg-text3',1.5,{ y : 0, opacity: 1,display: "block",top:0},{ y : -50, opacity: 0, top:0,display: "none" },'-=35')
        tl.fromTo('.js-svg-text4',1.5,{display: "none", opacity: 0 , y : 50 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=34')
        tl.fromTo('.js-svg-text4',2,{ y : 0, opacity: 1,display: "block",top:0},{ y : 0, opacity: 0, top:0,display: "none" },'-=11')
        tl.to('.main-Screen' ,{opacity:0, duration:2},'-=13')
        tl.to('#bodymovin',1.5,{opacity: 0,},'-=10');
        // tl.to('.svgAnimation-sec',{backgroundColor:'transparent',duration:0.5},'-=10')
        // tl.to('.svgAnimation-sec' ,{opacity:0, duration:1},'-=3')
        // tl.to('.cnavas-first-img' ,{opacity:0, duration:1})
        
        tl.to( airpods, {frame: frameCount - 1,snap: "frame",duration:85,onUpdate: render}) 
        tl.fromTo('.canvas-content1', {opacity:0, y : 35,duration: 2},{opacity:1 , y :0,duration: 2},'-=80')  
        tl.to('.canvas-content1',{opacity:0 , y :0,duration:1, display:'none'},'-=64') 
        tl.fromTo('.canvas-content2',{opacity:0, y : 35,display:'none',duration: 2},{opacity:1 , y :0,display:'block',duration: 2},'-=63')  

        tl.fromTo('.countForNine span:nth-child(1)',1 ,{ y : 0, opacity: 1,display: "block",position:'absolute', top:0},{ y : -15, opacity: 0,position:'absolute', top:0,display: "none" },'-=62')
        tl.fromTo('.countForNine span:nth-child(2)', 1, {display: "none", opacity: 0 , y : 15 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=61')

        tl.fromTo('.countForNine span:nth-child(2)',1 ,{ y : 0, opacity: 1,display: "block",position:'absolute', top:0},{ y : -15, opacity: 0,position:'absolute', top:0,display: "none" },'-=60')
        tl.fromTo('.countForNine span:nth-child(3)', 1, {display: "none", opacity: 0 , y : 15 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=59')

        tl.fromTo('.countForNine span:nth-child(3)',1 ,{ y : 0, opacity: 1,display: "block",position:'absolute', top:0},{ y : -15, opacity: 0,position:'absolute', top:0,display: "none" },'-=58')
        tl.fromTo('.js-change-lenght span:nth-child(1)', 1, { y : 0, opacity: 1,display: "block",position:'absolute', top:0},{ y : -15, opacity: 0,position:'absolute', top:0,display: "none" },'-=58')

        tl.fromTo('.js-change-lenght span:nth-child(2)',1 ,{opacity:0, y : 35,display:'none',duration: 2},{opacity:1 , y :0,display:'block',duration: 2},'-=57')
        tl.fromTo('.countForNine span:nth-child(4)', 1, {display: "none", opacity: 0 , y : 15 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=57')

        tl.fromTo('.countForNine span:nth-child(4)',1 ,{ y : 0, opacity: 1,display: "block",position:'absolute', top:0},{ y : -15, opacity: 0,position:'absolute', top:0,display: "none" },'-=56')
        tl.fromTo('.countForNine span:nth-child(5)', 1, {display: "none", opacity: 0 , y : 15 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=55')

        tl.fromTo('.countForNine span:nth-child(5)',1 ,{ y : 0, opacity: 1,display: "block",position:'absolute', top:0},{ y : -15, opacity: 0,position:'absolute', top:0,display: "none" },'-=54')
        tl.fromTo('.countForNine span:nth-child(6)', 1, {display: "none", opacity: 0 , y : 15 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=53')

        tl.fromTo('.countForNine span:nth-child(6)',1 ,{ y : 0, opacity: 1,display: "block",position:'absolute', top:0},{ y : -15, opacity: 0,position:'absolute', top:0,display: "none" },'-=52')
        tl.fromTo('.countForNine span:nth-child(7)', 1, {display: "none", opacity: 0 , y : 15 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=51')

        tl.fromTo('.countForNine span:nth-child(7)',1 ,{ y : 0, opacity: 1,display: "block",position:'absolute', top:0},{ y : -15, opacity: 0,position:'absolute', top:0,display: "none" },'-=50')
        tl.fromTo('.countForNine span:nth-child(8)', 1, {display: "none", opacity: 0 , y : 15 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=49')

        tl.fromTo('.countForNine span:nth-child(8)',1 ,{ y : 0, opacity: 1,display: "block",position:'absolute', top:0},{ y : -15, opacity: 0,position:'absolute', top:0,display: "none" },'-=48')
        tl.fromTo('.countForNine span:nth-child(9)', 1, {display: "none", opacity: 0 , y : 15 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=47')

        tl.fromTo('.countForNine span:nth-child(9)',1 ,{ y : 0, opacity: 1,display: "block",position:'absolute', top:0},{ y : -15, opacity: 0,position:'absolute', top:0,display: "none" },'-=46')
        tl.fromTo('.countForNine span:nth-child(10)', 1, {display: "none", opacity: 0 , y : 15 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=45')

        tl.fromTo('.js-onCounterText span:nth-child(1)',1 ,{ y : 0, opacity: 1,display: "inline-block",position:'absolute', top:0},{ y : -15, opacity: 0,position:'absolute', top:0,display: "none" },'-=43')
        tl.fromTo('.js-onCounterText span:nth-child(2)', 1, {display: "none", opacity: 0 , y : 15 },{display: "inline-block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=42')
        
        tl.fromTo('.countForNine span:nth-child(10)',1 ,{ y : 0, opacity: 1,display: "block",position:'absolute', top:0},{ y : -15, opacity: 0,position:'absolute', top:0,display: "none" },'-=41')
        tl.fromTo('.countForNine span:nth-child(11)', 1, {display: "none", opacity: 0 , y : 15 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=40')

        tl.fromTo('.countForNine span:nth-child(11)',1 ,{ y : 0, opacity: 1,display: "block",position:'absolute', top:0},{ y : -15, opacity: 0,position:'absolute', top:0,display: "none" },'-=39')
        tl.fromTo('.countForNine span:nth-child(12)', 1, {display: "none", opacity: 0 , y : 15 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=38')

        tl.to('.canvas-content2',{opacity:0 , y :-20,duration:2.5, display:'none'},'-=37') 
        // tl.fromTo('.countForNine span:nth-child(7)',2.5 ,{ y : 0, opacity: 1,display: "block",position:'absolute', top:0},{ y : -15, opacity: 0,position:'absolute', top:0,display: "none" },'-=36')
        // tl.fromTo('.countForNine span:nth-child(8)', 2.5, {display: "none", opacity: 0 , y : 15 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=36')
        
        
        
        // tl.to('.canvas-content2',{opacity:0 , y :0,duration:1, display:'none'},'-=30') 
        // tl.to('.fr-canvs', {opacity:0,duration:2})
        
        //Connect Section Animation Start From Here
        tl.fromTo('.connect-sec' ,{opacity: 0, duration: 1},{opacity: 1, duration: 1})
        tl.fromTo('.js-first-ul li:nth-child(2)' ,{opacity:1, x : 35,duration: 1.5},{opacity:1 , x:0,duration: 1.5},'+=7')
        tl.fromTo('.js-first-ul li:nth-child(3)' ,{opacity:0, x : -35,duration: 1.5},{opacity:1 , x:0,duration: 1.5},'-=1.5')
        tl.fromTo(".connect-text-wrapper span:nth-child(1)", 1.5,{ y : 0, opacity: 1.5,display: "block",position:'absolute', top:0},{ y : -15, opacity: 0,position:'absolute', top:0,display: "none" },'-=1.5')
        tl.fromTo(".connect-text-wrapper span:nth-child(2)", 1.5, {display: "none", opacity: 0 , y : 15 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=1.5')

        tl.fromTo('.js-first-ul li:nth-child(1)' ,{opacity:0, x : 70,duration: 1.5},{opacity:1 , x:0,duration: 1.5},'+=7')
        tl.fromTo('.js-first-ul li:nth-child(4)' ,{opacity:0, x : -70,duration: 1.5},{opacity:1 , x:0,duration: 1.5},'-=1.5')
        tl.to(".connect-text-wrapper span:nth-child(2)", 1.5,{ y : -15, opacity: 0,display: "none",position:'absolute', top:0, },'-=1.5')
        tl.fromTo(".connect-text-wrapper span:nth-child(3)", 1.5, {display: "none", opacity: 0 , y : 15 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=1.5')

        tl.fromTo('.js-second-ul li' ,{opacity:0, y : 80,duration: 1.5},{opacity:1 , y:0,duration: 1.5},'+=7')
        tl.fromTo('.js-third-ul li' ,{opacity:0, y : -80,duration: 1.5},{opacity:1 , y:0,duration: 1.5},'-=1.5')
        tl.to(".connect-text-wrapper span:nth-child(3)", 1.5,{ y : -15, opacity: 0,display: "none",position:'absolute', top:0, },'-=1.5')
        tl.fromTo(".connect-text-wrapper span:nth-child(4)", 1.5, {display: "none", opacity: 0 , y : 15 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=1.5')

        tl.fromTo('.js-fourth-ul li' ,{opacity:0, y : 40,duration: 1.5},{opacity:1 , y:0,duration: 1.5},'+=7')
        tl.fromTo('.js-fifth-ul li' ,{opacity:0, y : -40,duration: 1.5},{opacity:1 , y:0,duration: 1.5},'-=1.5')
        tl.to(".connect-text-wrapper span:nth-child(4)", 1.5,{ y : -15, opacity: 0,display: "none",position:'absolute', top:0, },'-=1.5')
        tl.fromTo(".connect-text-wrapper span:nth-child(5)", 1.5, {display: "none", opacity: 0 , y : 15 },{display: "block",position:'absolute', top:0, opacity: 1 , y : 0 },'-=1.5')

        //Last Two Section Animation Start From Here
        tl.fromTo('.flexibleWorkspace-sec', 5 ,{opacity: 0 ,top:0, display:'none', y : 0 },{opacity: 1 ,display:'flex', y : 0},"+=10")
        tl.fromTo('.fw-mainheading', 2.5 ,{opacity: 0 , y : 30},{opacity: 1 , y : 0} )
        tl.fromTo('.fw-para1', 2.5 ,{opacity: 0 , y : 30},{opacity: 1 , y : 0}, )
        tl.fromTo('.fw-para2', 2.5 ,{opacity: 0 , y : 30},{opacity: 1 , y : 0},'+=5')
        // tl.fromTo('.contPresentation-sec',1.5 ,{yPercent: 0, display:'none'}, {yPercent: -100,display:'flex', postion:'absolute'})
        tl.to('.contPresentation-sec',1.5 ,{yPercent: -100, postion:'absolute'})
        tl.to('.flexibleWorkspace-sec' ,{yPercent: -100, postion:'absolute',duration:6})
        tl.to('.contPresentation-sec',3 ,{yPercent: -100, postion:'absolute'}, "+=6")
    })

}
